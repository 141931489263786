<template>
    <v-container>
        <v-card v-if="caps.length">
            <v-simple-table color="blueDark">
                <template v-slot:default>
                <thead class="contract-table-head">
                    <tr>
                        <th class="text-left white--text body-1 font-weight-bold">
                            Name
                        </th>
                        <th class="text-left white--text body-1 font-weight-bold">
                            From
                        </th>
                        <th class="text-left white--text body-1 font-weight-bold">
                            To
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in caps" :key="item.id">
                        <td class="clickable-field" @click="$router.push({name: 'cap', params: {id: item.id}})">{{ item.name }}</td>
                        <td>{{ item.startDate }}</td>
                        <td>{{ item.endDate }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <p v-if="!caps.length && !$store.state.showLoading" class="text-center">No commissions caps found!</p>
    </v-container>
  </template>

<script>
import { weeks, headers } from '@/links'

export default {
  data () {
    return {
      caps: []
    }
  },
  created () {
    this.$store.dispatch('setLoadingState', true)
    this.$http.get(weeks, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      this.caps = response.body.data
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      this.loadingContracts = false
      this.$store.dispatch('setLoadingState', false)
    })
  }
}
</script>

<style scoped>
    .contract-table-head {
        background-color: #202847;
    }
    .clickable-field {
        cursor: pointer
    }
    .clickable-field:hover {
        color: #FDB71A;
    }
</style>
