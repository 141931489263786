<template>
    <v-container>
        <v-card v-if="cap">
            <v-simple-table color="blueDark">
                <template v-slot:default>
                <thead class="contract-table-head">
                    <tr>
                        <th class="text-left white--text body-1 font-weight-bold">
                          Name
                        </th>
                        <th class="text-center white--text body-1 font-weight-bold"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="clickable-field" @click="redirect(cap.file)">
                        <td class="text-left">{{ cap.name }}</td>
                        <td class="clickable-field text-center" @click="redirect(cap.file)">View File</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card>

        <p v-if="!cap && !$store.state.showLoading" class="text-center">No commission cap found!</p>
    </v-container>
  </template>

<script>
import { cap, headers } from '@/links'

export default {
  data () {
    return {
      cap: null
    }
  },
  methods: {
    redirect (file) {
      window.open(`https://view.officeapps.live.com/op/view.aspx?src=${file}`, '_blank')
    }
  },
  created () {
    this.$store.dispatch('setLoadingState', true)
    this.$http.get(cap(this.$route.params.id), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      this.cap = response.body.data[0]
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      this.loadingContracts = false
      this.$store.dispatch('setLoadingState', false)
    })
  }
}
</script>

<style scoped>
    .contract-table-head {
        background-color: #202847;
    }
    .clickable-field {
        cursor: pointer
    }
    .clickable-field:hover {
        color: #FDB71A;
    }
</style>
